<template>
    <div>
         <div class="news-box mainwidth">
            <div class="news-con" >
                <div class="news-top">
                    <p class="title">{{newsDetail.INFOR_TITLE}}</p>
                    <p class="typebox">
                    <span class="type " v-if="newsDetail.POLICY_LEVEL == '国'">{{newsDetail.POLICY_LEVEL}}</span>
                    <span class="type " v-if="newsDetail.POLICY_LEVEL == '省'" style="background: #ffb83c">{{newsDetail.POLICY_LEVEL}}</span>
                    <span class="type " v-if="newsDetail.POLICY_LEVEL == '市'" style="background: #9ad86c">{{newsDetail.POLICY_LEVEL}}</span>
                    <span class="type " v-if="newsDetail.POLICY_LEVEL == '区'" style="background: #8fa1f9">{{newsDetail.POLICY_LEVEL}}</span>
                        <span class="time">发布时间：{{newsDetail.PROM_DATE}}</span>
                    </p>
                </div>
                <div class="news-content" v-if="newsDetail.FILE_ADDR" style="text-align: center;margin-top: 20px;">
                    <img :src="imgApi + newsDetail.FILE_ADDR" style="width: 100%;">
                </div>
                <div class="news-content" v-html="newsDetail.INFOR"></div>
                <!-- <div class="news-bottom-box">
                    <div class="news-bottom">
                        <span>附件下载</span>
                        <p>
                            <span>阅览：547次</span>
                            <span>下载：126次</span>
                            <span>来源：<img src="" alt=""></span>
                        </p>
                    </div>
                </div> -->
            </div>
         </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Base from '@/api/base.js'
export default {
    computed: {
        ...mapGetters(['newsDetail'])
    },
    data () {
        return {
            imgApi: Base.cs + '/',
            id: ''
        }
    },
    methods: {
        getNewsDetail () {
            this.$store.dispatch('getNewsDetail', { INFORMATON_ID: this.id, tm: new Date().getTime() })
        }
    },
    created () {
        if (this.$route.query.id === '' || this.$route.query.id === null || this.$route.query.id === undefined) {
            this.$message.info('新闻不存在！')
            this.$router.go(-1)
            return false
        }
        this.id = this.$route.query.id
        this.getNewsDetail()
    }
}
</script>

<style lang="less" scoped>
.news-box{
    .news-con{
        padding: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: @color-ff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #929292;
        .news-top{
            border-bottom: @border-base;
            padding-bottom: 10px;
            p{
                margin-bottom: 10px;
            }
            .title{
                font-size: @font-x30;
                color: @color-base;
                font-weight: bold;
                text-align: center;
            }
            .typebox{
                text-align: center;
                .type{
                    padding: 3px;
                    font-size: @font-lg;
                    background-color: @color-guo;
                    color: @color-ff;
                    border-radius: 2px;
                    margin-right: 10px;
                }
                .type-guo{
                    background-color: @color-guo;
                    color: @color-ff;
                }
                .time{
                    font-size: @font-lg;
                    color: @color-base;
                }
            }
        }
        .news-content{
            width: 95%;
            margin: 20px auto;
            font-size: @font-lg;
            color: @color-base;
            line-height: 30px;
            text-align: justify;
        }
        .news-bottom-box{
            border-top: @border-base;
            .news-bottom{
                width: 95%;
                margin: 0px auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 15px;
                p{
                    margin-bottom: 0;
                    span{
                        margin: 0 15px;
                    }
                }
            }
        }
    }
}
</style>
